@import '~antd/dist/antd.css';
@import '~normalize.css';
@import './styles/fonts.css';

html {
  --color-yellow: #ffd54f;
  --color-yellow-dark: #ffc107;
  --color-yellow-disabled: #ffd54f;
  --color-yellow-hover: #ffcf28;
  --color-blue: #1565c0;
  --color-blue-dark: #0c4397;
  --color-blue-light: #e3f2fd;
  --color-blue-disabled: #91b3dc;
  --color-blue-hover: #0e4fb3;
  --color-blue-menu: #01579b;
  --color-blue-menu-hover: #e1f5fe;
  --color-blue-checkbox: #0277BD;
  --color-grey: #575757;
  --color-grey-dark: #3e3e3e;
  --color-error: #b00020;
  --color-disabled: #d7d7d7;
  --color-disabled-bg: #f5f5f5;
  --default-font-family: 'Roboto';
  --default-font-size: 16px;
  --min-screen-width: 320px;
}

#root, body {
  min-height: 100%;
  width: 100%;
  height: 100%;
  background-color: #fffcf9;
  box-sizing: border-box;
  font-family: var(--default-font-family), sans-serif;
  margin: 0;
  padding: 0;
}

body {
  font-size: 14px;
  line-height: 8px;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
