.root {
  display: flex;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
}

.fullPage {
  width: 100vw;
  height: 100vh;
}
